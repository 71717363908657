.user_circle_green,
.user_circle_idle,
.user_circle_empty,
.user_circle_empty_line {
    border-radius: 50%;
    border: 1px solid;
}

.user_sidebar_entry.with_avatar .user_circle {
    outline: 1px solid var(--color-background);
}

.user_circle_green {
    background-color: var(--color-user-circle-active);
    border-color: var(--color-user-circle-active);
}

.user_circle_idle {
    border-color: var(--color-user-circle-idle);
    background: linear-gradient(
        to bottom,
        hsl(0deg 0% 100% / 0%) 50%,
        var(--color-user-circle-idle) 50%
    );
}

.user_circle_empty {
    background-color: transparent;
    border-color: hsl(0deg 0% 50%);
}

.user_circle_empty_line {
    border-color: hsl(0deg 0% 50%);

    &::after {
        content: "";
        background: hsl(0deg 0% 50%);
        height: 1.5px; /* 1px is too thin, 2px is too thick */
        width: 6px;
        display: block;
        margin: 3.25px auto 0; /* (total height - line height) / 2 = 3.25px */
    }
}
